<template>
  <div>
    <v-stepper-content
      v-if="parent.underwritingStep === 1"
      step="3"
    >
      <v-form
        ref="form2"
        v-model="parent.valid"
        lazy-validation
        @submit.prevent="changeUnderwritingStep"
      >
        <validation-observer
          ref="observer2"
          v-slot="{ invalid }"
        >
          <v-card
            min-height="300"
            elevation="0"
          >
            <div
              class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
            >
              {{ $t('caTerm.lifestyleQuestions.title') }}
            </div>
            <v-row class="justify-center">
              <v-col
                cols="11"
                md="8"
              >
                <div>
                  <p class="mt-2">
                    {{ $t('caTerm.lifestyleQuestions.chooseUnitsOfMeasurement') }}
                  </p>
                  <div>
                    <!-- Radio group for selecting unit -->
                    <v-radio-group
                      v-model="parent.units"
                      row
                      @change="resetFields"
                    >
                      <v-radio
                        :label="$t('caTerm.lifestyleQuestions.imperial')"
                        value="imperial"
                        name="imperial"
                      ></v-radio>
                      <v-radio
                        :label="$t('caTerm.lifestyleQuestions.metric')"
                        value="metric"
                        name="metric"
                      ></v-radio>
                    </v-radio-group>

                    <!-- Input fields based on the selected unit -->
                    <p>{{ $t('caTerm.lifestyleQuestions.enterHeight') }}</p>
                    <v-row
                      v-if="parent.units === 'imperial'"
                      class="d-inline-flex"
                    >
                      <v-col
                        cols="11"
                        sm="5"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Feet"
                          :rules="{
                            required:true,
                            numeric:true,
                            unitRange:parent.units==='imperial'?[4,6,'feet']:[135,200,'cm']
                          }"
                        >
                          <v-text-field
                            v-model.number="parent.user.feet"
                            v-mask="'#'"
                            :error-messages="errors"
                            :label="$t('caTerm.lifestyleQuestions.feet')"
                            outlined
                            class="height-style"
                            name="feet"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="11"
                        sm="5"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Inches"
                          :rules="{required:true,
                                   numeric:true,
                                   unitRange:parent.units==='imperial'?[0,11,'inches']:[135,200,'cm']
                          }"
                        >
                          <v-text-field
                            v-model.number="parent.user.inches"
                            v-mask="'##'"
                            :label="$t('caTerm.lifestyleQuestions.inches')"
                            outlined
                            :error-messages="errors"
                            class="height-style"
                            name="inches"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <div v-if="parent.units === 'metric'">
                      <v-col v-bind="parent.colAttributes">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Centimeters"
                          rules="required|range:135,200"
                        >
                          <v-text-field
                            v-model="parent.user.centimeters"
                            :label="$t('caTerm.lifestyleQuestions.centimeters')"
                            :error-messages="errors"
                            outlined
                            class="centimeter-style"
                            name="centimeters"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </div>
                  </div>
                  <p>{{ $t('caTerm.lifestyleQuestions.enterWeight') }}</p>
                  <v-col v-bind="parent.colAttributes">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Weight"
                      :rules="{
                        required:true,
                        numeric:true,
                        'unitRange':parent.units==='imperial'?[82.67,313.06,'lbs']:[37.5,142,'kg']
                      }"
                    >
                      <v-text-field
                        v-model="parent.weightModel"
                        :label="parent.weightLabel"
                        outlined
                        color="primary"
                        :error-messages="errors"
                        @blur="parent.checkWeight()"
                        @keyup="fillWeight"
                        class="weight-style"
                        :name="parent.units==='imperial' ? 'lbs': 'kg'"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </div>
                <!-------------------------------------LIFESTYLE UNDERWRITING QUESTIONS---------------------------------------->
                <div
                  v-for="(question,index) in parent.lifestyleUnderwritingQuestions"
                  :key="index"
                  class="p-style"
                >
                  <p>{{ question.text }}</p>
                  <ul v-if="question?.list">
                    <li
                      v-for="(listItem,listItemIndex) in question?.list"
                      :key="listItemIndex"
                    >
                      {{ listItem }}
                    </li>
                  </ul>
                  <validation-provider
                    v-if="question?.type==='text'"
                    v-slot="{ errors }"
                    name="question?.name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="question.answer"
                      v-mask="'##'"
                      :error-messages="errors"
                      :label="$t('caTerm.lifestyleQuestions.amount')"
                      outlined
                      name="amount"
                    />
                  </validation-provider>
                  <validation-provider
                    v-if="question?.type==='radio'"
                    v-slot="{ errors }"
                    rules="requiredOption"
                  >
                    <v-radio-group
                      v-model="parent.lifestyleUnderwritingQuestions[index]['answer']"
                      row
                      :error-messages="errors"
                    >
                      <v-radio
                        v-for="(option, optionIndex) in $t('forms.booleanOptions')"
                        :key="optionIndex"
                        :label="option.label"
                        :value="option.value"
                        :name="index + optionIndex"
                      ></v-radio>
                    </v-radio-group>
                  </validation-provider>
                  <div
                    v-if="question?.conditionalQuestion && question.answer === question?.conditionalQuestion?.condition"
                  >
                    <p>{{ question.conditionalQuestion.text }}</p>
                    <!-- Text Type -->
                    <validation-provider
                      v-if="question?.conditionalQuestion.type==='text'"

                      v-slot="{ errors }"
                      :name="question.conditionalQuestion?.name"
                      rules="required|min_value:1"
                    >
                      <v-text-field
                        v-model="question.conditionalQuestion.answer"
                        v-mask="'##'"
                        outlined
                        :error-messages="errors"
                        :name="question.conditionalQuestion?.name"
                      />
                    </validation-provider>

                    <!-- Radio Button                              -->
                    <validation-provider
                      v-if="question?.conditionalQuestion.type==='radio'"
                      v-slot="{ errors }"
                      rules="requiredOption"
                    >
                      <v-radio-group
                        v-model="question.conditionalQuestion.answer"
                        row
                        :error-messages="errors"
                      >
                        <v-radio
                          v-for="(option, i) in question.conditionalQuestion.options"
                          :key="i"
                          :label="option.text"
                          :value="option.value"
                          :name="`${'conditionalQuestion'+ i}`"
                        ></v-radio>
                      </v-radio-group>
                    </validation-provider>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-row class="pa-2 ma-2  d-flex justify-center">
            <v-btn
              x-large
              class="white--text font-weight-bold style-back"
              @click="parent.back"
            >
              {{ $t('common.buttons.back') }}
            </v-btn>
            <v-btn
              x-large
              color="primary"
              class="font-weight-bold"
              @click="
                //log(policyData);
                changeUnderwritingStep(2);
              "
            >
              {{ $t('common.buttons.continue') }}
            </v-btn>
          </v-row>
        </validation-observer>
      </v-form>
    </v-stepper-content>
    <!-------------------------------------MEDICAL QUESTIONS---------------------------------------->
    <v-stepper-content
      v-if="parent.underwritingStep === 2"
      step="3"
    >
      <v-form
        ref="form3"
        v-model="parent.valid"
        lazy-validation
        @submit.prevent="changeMedicalUnderwritingStep"
      >
        <validation-observer
          ref="observer3"
          v-slot="{ invalid }"
        >
          <v-card
            min-height="300"
            elevation="0"
            class="px-8"
          >
            <div
              class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
            >
              {{ $t('caTerm.medicalQuestions.title') }}
            </div>
            <v-row class="justify-center">
              <v-col
                cols="11"
                md="8"
              >
                <div>
                  <p class="font-weight-bold text-h4">
                    {{ $t('caTerm.medicalQuestions.text') }}
                  </p>
                  <div
                    v-for="([key, condition]) in Object.entries(parent.healthConditions).slice(0, 11)"
                    :key="key"
                    class="p-style"
                  >
                  <div v-if="condition.active">
                    <div
                      v-html="condition.text"
                    >
                    </div>
                    <validation-provider
                      v-if="condition?.type==='radio'"
                      v-slot="{ errors }"
                      rules="requiredOption"
                    >
                      <v-radio-group
                        v-model="condition['answer']"
                        row
                        :error-messages="errors"
                      >
                        <v-radio
                          v-for="(option, optionIndex) in $t('forms.booleanOptions')"
                          :key="optionIndex"
                          :label="option.label"
                          :value="option.value"
                          :name="key + optionIndex"
                        ></v-radio>
                      </v-radio-group>
                    </validation-provider>
                   <!-- Condtion question for medical start -->
                  <div
                    v-if="condition?.conditionalQuestion && condition.answer === condition?.conditionalQuestion?.condition"
                  >
                    <p>{{ condition.conditionalQuestion.text }}</p>
                    <!-- Text Type -->
                    <validation-provider
                      v-if="condition?.conditionalQuestion.type==='text'"

                      v-slot="{ errors }"
                      :name="condition.conditionalQuestion?.name"
                      rules="required|min_value:1"
                    >
                      <v-text-field
                        v-model="condition.conditionalQuestion.answer"
                        v-mask="'##'"
                        outlined
                        :error-messages="errors"
                        :name="`${'health'+ key}`"
                      />
                    </validation-provider>

                    <!-- Radio Button -->
                    <validation-provider
                      v-if="condition?.conditionalQuestion.type==='radio'"
                      v-slot="{ errors }"
                      rules="requiredOption"
                    >
                      <v-radio-group
                        v-model="condition.conditionalQuestion.answer"
                        row
                        :error-messages="errors"
                      >
                        <v-radio
                          v-for="(option, i) in condition.conditionalQuestion.options"
                          :key="i"
                          :label="option.text"
                          :value="option.value"
                          :name="`${'healthConditional'+ i}`"
                        ></v-radio>
                      </v-radio-group>
                    </validation-provider>
                  </div>
                  </div>
                  <!-- Condtion question for medical end -->
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-row class="pa-2 ma-2  d-flex justify-center">
            <v-btn
              x-large
              class="white--text font-weight-bold style-back"
              @click="backUnderwritingStep"
            >
              {{ $t('common.buttons.back') }}
            </v-btn>
            <v-btn
              x-large
              class="font-weight-bold"
              color="primary"
              :disabled="invalid"
              @click="
                changeMedicalUnderwritingStep(3);
              "
            >
              {{ $t('common.buttons.continue') }}
            </v-btn>
          </v-row>
        </validation-observer>
      </v-form>
    </v-stepper-content>
    <v-stepper-content
      v-if="parent.underwritingStep === 3"
      step="3"
    >
      <validation-observer
        ref="observer4"
        v-slot="{ invalid }"
      >
        <v-card
          min-height="300"
          elevation="0"
          class="px-8"
        >
          <div
            class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
          >
            {{ $t('caTerm.medicalQuestions.title') }}
          </div>
          <v-row class="justify-center">
            <v-col
              cols="11"
              md="8"
            >
              <div>
                <div
                   v-for="([key, condition]) in Object.entries(parent.healthConditions).slice(11)"
                  :key="key"
                  class="p-style"
                >
                  <div
                    v-html="condition.text"
                  >
                  </div>
                  <ul v-if="condition?.list">
                    <li
                      v-for="(item,index) in condition?.list"
                      :key="index"
                    >
                      {{ item }}
                    </li>
                  </ul>
                  <validation-provider
                    v-if="condition?.type==='radio'"
                    v-slot="{ errors }"
                    rules="requiredOption"
                  >
                    <v-radio-group
                      v-model="condition['answer']"
                      row
                      :error-messages="errors"
                    >
                      <v-radio
                        v-for="(option, optionIndex) in $t('forms.booleanOptions')"
                        :key="optionIndex"
                        :label="option.label"
                        :value="option.value"
                        :name="key + optionIndex"
                      ></v-radio>
                    </v-radio-group>
                  </validation-provider>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-row class="pa-2 ma-2  d-flex justify-center">
          <v-btn
            x-large
            class="white--text font-weight-bold style-back"
            @click="backUnderwritingStep"
          >
            {{ $t('common.buttons.back') }}
          </v-btn>
          <v-btn
            x-large
            class="font-weight-bold"
            :disabled="invalid"
            color="primary"
            @click="moveToReview"
          >
            {{ $t('common.buttons.continue') }}
          </v-btn>
        </v-row>
      </validation-observer>
    </v-stepper-content>
    <v-stepper-content
      v-if="parent.underwritingStep === 4"
      step="3"
    >
      <div class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text">
        {{ $t('common.reviewAnswers') }}
      </div>
      <summary-preview
        :go-to-step="()=>{
          parent.applicationStep=1;
          parent.underwritingStep=1
        }"
        :questions="parent.surveyQuestions"
        :title="$t('caTerm.preApplication')"
        btnName="summaryBtn1"
      ></summary-preview>
      <summary-preview
        :go-to-step="()=>{
          parent.applicationStep=3;
          parent.underwritingStep=1
        }"
        :questions="[
          {text:'Height',answer:`${parent.payloadHeight}`},
          {text:'Weight',answer:`${parent.payloadWeight}`},
          ...Object.values(parent.lifestyleUnderwritingQuestions)
          ]"
        :title="$t('caTerm.lifestyleQuestions.title')"
        btnName="summaryBtn2"
      ></summary-preview>
      <summary-preview
        :go-to-step="()=>{
          parent.applicationStep=3;
          parent.underwritingStep=2
        }"
        :questions="Object.values(parent.healthConditions).slice(0, 11)"
        :title="$t('caTerm.medicalQuestions.title')"
        btnName="summaryBtn3"
      ></summary-preview>

      <summary-preview
        :go-to-step="()=>{
          parent.applicationStep=3;
          parent.underwritingStep=3
        }"
        :questions="Object.values(parent.healthConditions).slice(11)"
        :title="$t('caTerm.medicalQuestions.title')"
        btnName="summaryBtn4"
      ></summary-preview>

      <v-row class="pa-2 ma-2 d-flex justify-center">
        <v-btn
          x-large
          class="white--text font-weight-bold style-back"
          @click="backUnderwritingStep"
        >
          {{ $t('common.buttons.back') }}
        </v-btn>
        <v-btn
          x-large
          class="font-weight-bold"
          color="primary"
          @click="
            parent.applicationStep++
          "
          name="continue3"
        >
          {{ $t('common.buttons.continue') }}
        </v-btn>
      </v-row>
    </v-stepper-content>
  </div>
</template>
<script>
  import SummaryPreview from '@/views/dashboard/CATerm/SummaryPreview.vue'
  export default {
    components: {
      SummaryPreview
    },
    props: ['parent'],
    computed: {
      weightModel: {
        get () {
          return this.parent.units === 'metric' ? this.parent.user.kilograms : this.parent.user.pounds
        },
        set (value) {
          if (this.parent.units === 'metric') {
            this.parent.user.kilograms = value
          } else {
            this.parent.user.pounds = value
          }
          this.$emit('weight-set', value)
        },
      }
    },
    watch: {
      weightModel: {
        handler (value, oldValue) {
          const weight = value
          if (this.parent.units === 'metric') {
            this.parent.user.kilograms = weight
          } else {
            this.parent.user.pounds = weight
          }
          this.$emit('weight-set', weight)
          this.parent.weight = weight
        }
      },
      'parent.healthConditions.sleepApnea.answer' (val) {
        if (val) {
          this.parent.healthConditions.sleepApnea.conditionalQuestion.answer = null
        }
      },
      'parent.healthConditions.schizophrenia.answer' (val) {
        if (val === false) {
          this.parent.healthConditions.depression.active = true
        }
        if (val === true) {
          this.parent.healthConditions.anxiety.active = false
          this.parent.healthConditions.anxiety.answer = null
          if (this.parent.healthConditions.depression.active) {
            this.parent.healthConditions.depression.answer = null
            this.parent.healthConditions.depression.active = false
          }
        }
      },
      'parent.healthConditions.depression.answer' (val) {
        if (val === false) {
          this.parent.healthConditions.anxiety.active = true
        }
        if (val === true) {
          this.parent.healthConditions.anxiety.active = false
          this.parent.healthConditions.anxiety.answer = null
        }
      },
      'parent.lifestyleUnderwritingQuestions.marijuanaUse.conditionalQuestion.answer' (newValue) {
        let formattedValue

        if (newValue <= 3) formattedValue = '0-3'
        else if (newValue <= 6) formattedValue = '4-6'
        else if (newValue > 6) formattedValue = '7+'

        this.parent.marijuanaFormatted = formattedValue
      },
      'parent.lifestyleUnderwritingQuestions.alcoholUse.answer' (newValue) {
        let formattedValue

        if (newValue <= 7) {
          formattedValue = '0-7'
        } else if (newValue <= 14) {
          formattedValue = '8-14'
        } else if (newValue <= 21) {
          formattedValue = '15-21'
        } else {
          formattedValue = '21 or more'
        }
        this.parent.alcoholFormatted = newValue
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.$emit('step-ref', this)
      })
    },
    methods: {
      fillWeight (val) {
        const value = val.target.value
        if (this.parent.units === 'metric') {
          this.parent.user.kilograms = value
        } else {
          this.parent.user.pounds = value
        }
        parent.weightModel = value
      },
      async changeUnderwritingStep (step) {
        try {
          const [observer2Result, form2Result] = await Promise.all([
            this.$refs.observer2.validate(),
            this.$refs.form2.validate()
          ])

          if (!observer2Result || !form2Result) {
            this.parent.scrollToError()
            console.log('Validation failed.')
            return
          }

          this.parent.underwritingStep = step
        } catch (error) {
          console.error('Error in changing steps', error)
        }
      },
      resetFields () {
        console.log('resetHeightWeight')
        this.parent.user.feet = null
        this.parent.user.inches = null
        this.parent.user.centimeters = null
        this.parent.user.kilograms = null
        this.parent.user.pounds = null
        this.parent.weightModel = null
        this.$refs.observer2.reset('Weight')
      },
      async changeMedicalUnderwritingStep () {
        try {
          const [form3Result] = await Promise.all([
            this.$refs.form3.validate()
          ])

          if (!form3Result) {
            console.log('Validation failed.')
            this.parent.scrollToError()
            return // Exit the function if the validation failed.
          }

          this.parent.underwritingStep = 3
        } catch (error) {
          console.error('Error in changing steps', error)
        }
      },
      backUnderwritingStep () {
        this.parent.underwritingStep--
      },
      moveToReview () {
        this.parent.underwritingStep = 4
        this.parent.underwritingStepIndex = 5
      }
    }
  }
</script>

<style scoped>
::v-deep .v-input--selection-controls.v-input .v-label {
  font-weight: 400 !important;
}
::v-deep .v-input--selection-controls.v-input .v-label {
  font-weight: 600;
  color: black;
  margin-bottom: 0;
}
::v-deep .weight-style .v-input__slot,
::v-deep .centimeter-style .v-input__slot,
::v-deep .v-messages__message{
  width: 156px!important;
}
@media screen and (max-width: 599px) {
 ::v-deep .height-style .v-input__slot,
 ::v-deep .v-messages__message{
  width: 156px!important;
}
}
.p-style{
  font-size: 16px;
}
</style>
