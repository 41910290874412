<template>
  <v-stepper-header
    v-if="parent.applicationStep<8"
    class="custom-style-header"
  >
    <v-stepper-step
      v-for="(step,index) in $t('caTerm.steps')"
      :key="index"
      :complete="parent.applicationStep >= index+1"
      :step="index+1"
      class="cursor-enabled scrollbar-item"
      @click="gotoStep(index,index+1)"
    >
      {{ step }}
      <template v-for="(subStep, idx) in $t('caTerm.policyOptionsSubsteps')">
        <div
          v-if="index === 1"
          :key="idx"
          class="cursor-enabled"
          @click="gotoSubStep(index+1,idx+1)"
        >
          <div
            class="sub-sub-header"
            :title="subStep"
          >
            <span :class="'icon-custom-style ' + getIconColor(index+1, idx) + '-style'">
              <v-icon
                :color="getIconColor(index+1, idx)"
              >
                mdi-check-circle
              </v-icon>
            </span>
            {{ subStep }}
          </div>
        </div>
      </template>
      <template v-for="(subStep, idx) in $t('caTerm.UnderwritingSubsteps')">
        <div
          v-if="index === 2"
          :key="idx"
          class="cursor-enabled"
          @click="gotoSubStep(index+1,idx+1)"
        >
          <div
            :id="'sub-header-' + index +idx"
            class="sub-sub-header"
            :title="subStep"
          >
            <span :class="'icon-custom-style ' + getIconColor(index+1, idx) + '-style'">
              <v-icon
                :color="getIconColor(index+1, idx)"
              >
                mdi-check-circle
              </v-icon>
            </span>
            {{ subStep }}
          </div>
        </div>
      </template>
    </v-stepper-step>
  </v-stepper-header>
</template>
<script>

  import { applicationSteps } from '../../../../utils/common'

  export default {
    props: {
      parent: {
        type: Object,
        reuired: true
      },
      combinedData: {
        type: Object,
        reuired: true
      }
    },
    methods: {
      async gotoStep (step, complete) {
        const { step1, step2, step3, step4, step5, step6 } = this.combinedData
        if (step + 1 === this.parent.applicationStep) {
          return
        }
        let isValidated = true
        let getCallback
        for (let i = 1; i <= step; i++) {
          switch (i) {
            case 4:
              getCallback = async () => {
                if (step4.allTermsAgreed) {
                  const formRef = await this.$store.dispatch('getCATermDecision', this.parent.decisionPayload)
                  return this.parent.decision !== 'Declined'
                }
                return false
              }
              break
            case 5:
              getCallback = () => this.parent.totalPercentage === 100 || this.parent.continueWithoutBeneficiary
              break
            case 6:
              getCallback = async () => {
                const formRef = step6.$refs.paynowObserver
                return formRef ? await formRef.validate() && step6.isFormValid : false
              }
              break
            case 2:
              getCallback = async () => {
                const formRef = step2.$refs.assessmentObserver
                const isValid = await formRef.validate()
                if (isValid) {
                  await step2.getRates()
                  if (!this.parent.errorMessage) {
                    this.parent.optionsStep = 2
                  }
                }
                return isValid
              }
              break
            case 3:
              getCallback = async () => {
                return this.parent.underwritingStep === 4
              }
              break
            default:
              getCallback = async () => {
                const formRef = step1.$refs[`observer${i}`]
                return formRef ? await formRef.validate() : true
              }
          }
          const formResult = await getCallback()
          if (!formResult) {
            isValidated = false
            break
          }
        }
        if (isValidated) {
          await this.$router.push(`/canadianterm/survey/${applicationSteps[complete]}/${this.parent.applicationId}`)
          this.parent.applicationStep = complete
        }
      },
      getIconColor (step, idx) {
        const getSubStep = step === 2 ? this.parent.optionsStep : this.parent.underwritingStep
        if (this.parent.applicationStep >= step && getSubStep >= idx + 1) {
          return 'primary'
        } else if (this.parent.applicationStep >= step && idx === 0) {
          return 'primary'
        } else {
          return 'grey'
        }
      },
      async gotoSubStep (step, subStep) {
        const { step2, step3 } = this.combinedData
        if (step === 2) {
          const formRef = await step2.$refs.assessmentObserver?.validate() || false
          if (subStep === 1) {
            this.parent.optionsStep = 1
          } else if (subStep === 2 && this.parent.assessedInsuranceNeed && formRef) {
            console.log(step2)
            await step2.getRates()
            if (!this.parent.errorMessage) {
              this.parent.optionsStep = 2
            }
          }
        }
        // For Underwriting
        if (step === 3) {
          const formRef2 = await step3.$refs.observer2?.validate() || false
          const formRef3 = await step3.$refs.observer3?.validate() || false
          const formRef4 = await step3.$refs.observer4?.validate() || false
          // Handle cases where underwriting step matches subStep
          if (this.parent.underwritingStep === subStep) {
            return
          }
          if (subStep === 1) {
            this.parent.underwritingStep = 1
          } else if ((subStep === 2 && formRef2) || (subStep === 2 && this.parent.underwritingStepIndex > subStep)) {
            this.parent.underwritingStep = 2
          } else if ((subStep === 3 && formRef3) || (subStep === 3 && this.parent.underwritingStepIndex > subStep)) {
            this.parent.underwritingStep = 3
          } else if ((subStep === 4 && formRef4) || (subStep === 4 && this.parent.underwritingStepIndex > subStep)) {
            this.parent.underwritingStep = 4
            this.parent.underwritingStepIndex = 5
          }
        }
      }
    }
  }
</script>
<style lang="scss">
@import "@/sass/caterm/steperHeader.scss";
</style>
